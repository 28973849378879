<template>
  <div id="product">
    <a-tabs type="card" @change="callback">
      <a-tab-pane
        :key="index"
        :tab="item.title"
        v-for="(item, index) in config_list"
      >
        <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }">
          <a-form-item
            :label="obj.title"
            v-for="(obj, i) in item.child_list"
            :key="i"
          >
            <a-input
              v-model="addFromData[obj.code]"
              v-if="obj.type == 'text'"
            />
            <a-textarea
              v-else-if="obj.type == 'textarea'"
              v-model="addFromData[obj.code]"
              :auto-size="{ minRows: 5, maxRows: 8 }"
            />

            <UE
              v-else-if="obj.type == 'content'"
              :sentContent="addFromData[obj.code]"
              :code="obj.code"
              @getUeContent="getUeContent"
            ></UE>

            <div
              v-else-if="obj.type == 'image'"
              @click="uploadClick(obj.code, index, i)"
            >
              <a-upload
                list-type="picture-card"
                class="avatar-uploader"
                :show-upload-list="false"
                :data="{ id: 1 }"
                :action="uploadImage"
                :before-upload="beforeUpload"
                @change="handleChange"
              >
                <img
                  v-if="obj.rule_url"
                  :src="obj.rule_url"
                  style="display:block;max-width:100px;max-height:100px"
                />
                <div v-else>
                  <a-icon :type="loading ? 'loading' : 'plus'" />
                </div>
              </a-upload>
            </div>
            <p v-if="obj.desc">{{obj.desc}}</p>
          </a-form-item>

          <a-form-item>
            <a-button type="primary" style="margin-left:150px" @click="submit">
              保存
            </a-button>
          </a-form-item>
        </a-form>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { configIndex, configSaveConfig, uploadImage } from "@/apis/index";
import UE from "@/components/UE/UE.vue";

export default {
  data() {
    return {
      addFromData: {},
      config_list: [],

      uploadImage,
      loading: false,

      uploadObj: {},
    };
  },
  components: {
    UE,
  },
  created() {
    configIndex().then((res) => {
      if (res.code == 1) {
        console.log(res)

        this.addFromData = res.data.child_list;
        this.config_list = res.data.config_list;
      }
    });
  },
  methods: {
    submit() {
      configSaveConfig(this.addFromData).then((res) => {
    
        if (res.code == 1) {
          this.$message.success("修改成功");
        }
      });
    },
    uploadClick(code, index, i) {
      this.uploadObj = { code, index, i };
    },
    callback() {
    
    },
    getUeContent(val, code) {
      this.addFromData[code] = val;
    },
    handleChange(info) {
     
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        this.config_list[this.uploadObj.index].child_list[
          this.uploadObj.i
        ].rule_url = info.file.response.data.url;
        this.addFromData[this.uploadObj.code] = info.file.response.data.id;
        this.loading = false;
      }
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("Image must smaller than 2MB!");
      }
      return isJpgOrPng && isLt2M;
    },
  },
};
</script>

<style lang="less" scoped>
#product {
  padding-top: 30px;
  width: 1200px;
  margin: 0 auto;
}
</style>
